@import '@angular/cdk/overlay-prebuilt.css';
@import 'moapr';

.toolkit {
  &__heading {
    margin-bottom: 3rem;
  }
  &__body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__content {
      width: calc(100% - 16rem);
      padding-right: 3rem;
      height: calc(100vh - 23rem);
      overflow-y: auto;
      &__block {
        margin: 0 0 4rem;
        position: relative;
        > .page-title {
          border-bottom: 1px solid var(--gray-400);
          padding: 0 0 0.5rem;
          margin: 0 0 1rem;
        }
        &__tabbox {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    &__scrollspy {
      width: 14rem;
      ul {
        padding-left: 1rem;
        list-style: none;
        > li {
          margin: 0 0 0.5rem;
          padding: 0 0 0.5rem;
          border-bottom: 1px solid var(--gray-400);
          cursor: pointer;
          color: var(--gray-500);
          &.is-active {
            color: var(--primaryColor);
            font-weight: 500;
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      &__content {
        width: 100%;
      }
      &__scrollspy {
        display: none;
      }
    }
  }
  &__screencast {
    width: 100%;
    height: 0;
    padding: 0 0 56.25%;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
