nxh-patient-nav {
  top: 0;
  z-index: 500;
  position: absolute;
  width: 100vw;
  left: 0;
}

.patient-page__container {
  padding-top: 3.5rem;
  height: 100%;
  width: calc(100% - 2.5rem);
  transition: width 0.3s ease;

  &.mud-open {
    width: calc(100% - var(--sidebarWidth));
    @media screen and (max-width: 1280px) {
      width: calc(100% - 2.5rem);
    }
  }
}

.patient-page__content {
  padding: 1.25rem 1.5rem 1.5rem;
  height: 100%;
  overflow-y: auto;
  position: relative;

  // backwards compatibility - nxh-page or nxh-page-tabs have their own padding
  &:has(nxh-page-tabs),
  &:has(nxh-page) {
    padding: 0;
  }

  &:has(nxh-page) {
    padding: 0;
  }
}

.patient-nav__administrative {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  color: var(--gray-700);
  font-size: 0.75rem;
  padding-left: 2rem;
  @media screen and (max-width: 1280px) {
    padding-left: 1rem;
  }

  > * {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .patient-nav__administrative__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding-right: 0.75rem;
      margin-right: 0.75rem;
      border-right: 1px solid var(--gray-400);

      fa-icon {
        padding-right: 0.25rem;
        color: var(--gray-600);
      }
    }

    &:last-child {
      > .patient-nav__administrative__item {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }
    }
  }
}
