// default theme
:root {
  font-size: 16px;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
  --nav-bar-height: 47px;
  --nav-bar-button-height: var(--nav-bar-height);
  --nav-bar-button-width: 56px;

  // primary
  --primaryColor: #167fac;
  --primaryColorRgb: 22, 127, 172;
  --primaryColorLight: #4499bd;
  --primaryColorDark: #11658a;
  --primaryColorWashed: #e8f3f7;
  --primaryColorWashed30: #b9d8e6;
  --primaryColorAlt: #bfd8e3;

  // secondary
  --secondaryColor: #721d73;
  --secondaryColorDark: #5b175c;
  --secondaryColorWashed: #f3edf4;
  --secondaryColorWashed30: #d4bbd5;

  // neutral
  --neutralColor: var(--gray-600);
  --neutralColorLight: var(--gray-500);
  --neutralColorDark: var(--gray-700);
  --neutralColorWashed: var(
    --gray-400
  ); // TODO according to the design system this should be gray-300 and the washed30 should be gray-400

  // danger
  --dangerColor: #d12f30;
  --dangerColorLight: #db5859;
  --dangerColorDark: #a72526;
  --dangerColorWashed: #fbebeb;
  --dangerColorWashed30: #f1c1c1;
  --dangerColorWashedHover: #fcdedf;
  --dangerColorLightWashed: #fefafa;

  // success
  --successColor: #02875b;
  --successColorLight: #349f7c;
  --successColorDark: #016c49;
  --successColorWashed: #e6f3ef;
  --successColorWashed30: #b4dbce;
  --successColorBright: #12ae3d;

  // warning
  --warningColor: #f2aa46;
  --warningColorLight: #f5bb6b;
  --warningColorDark: #da993f;
  --warningColorWashed: #fef7ed;
  --warningColorWashed30: #fbe6c8;

  --disabledColor: var(--gray-500);

  //yellow
  --yellow: #ffe142;
  // gray
  --white: #fff;
  --gray-100: #fafcfe;
  --gray-200: #f5f8fb;
  --gray-300: #f2f6f9;
  --gray-400: #e5ebef;
  --gray-500: #a6b2c4;
  --gray-600: #68798e;
  --gray-700: #4e5f70;
  --gray-800: #0c1827;
  --grayAthans: #e5ebef; // from the styleguide
  // body
  --bodyBGColor: #f3f7fa;
  --bodyTextColor: #0c1827;
  // header
  --headerBGColor: white;
  --headerBorderColor: #f2f6f9;
  --headerNavItemColor: #4e5f70;
  --headerBoxShadowColor: #f2f6f9;
  // navbar
  --navbarHoverColor: #f5f8fb;
  // expandable sidebar navigation
  --sidebarNavActiveColor: #e2edf1;
  --sidebarBGColor: var(--gray-100);
  // patient context bar
  --patientbarBGColor: #fafcfe;
  --patientbarTextColor: #68748e;
  // cards
  --cardBGColor: #ffffff;
  --cardBorderRadius: 4px;
  --cardBoxShadowColor: rgba(196, 199, 210, 0.5);
  --cardBoxShadow: 0 0.125rem 0.5rem 0 rgba(144, 150, 170, 0.1);
  // forms
  --formLabelColor: var(--gray-600);
  // buttons
  --buttonDisabledColor: #a6afc4;

  //colorVariants
  --lightOrangeWashedColor: #ffe9c5;
  --lightGreenWashedColor: #cffbc8;
  --darkerGreenWashedColor: #81d09a;
  --darkGreenWashedColor: #d6e7dc;
  --darkBlueWashedColor: #c7d9fb;
  --lightBlueWashedColor: #c7f3fb;
  --lightRedWashedColor: #fbc7d8;
  --redWashedColor: #fbabab;
  --purpleWashedColor: #dbc7fb;
  --yellowWashedColor: #fff5b2;
  --greyWashedColor: var(--gray-200);
  --greyDarkInvertColor: var(--buttonDisabledColor);
  --undefinedColor: var(--gray-200); //to be defined

  //lifecycle status colors
  --lifecycleActiveColor: #02875b;
  --lifecycleAntecedentColor: #f2aa46;
  --lifecycleArchiveColor: var(--gray-500);
  --lifecycleNotPresentColor: var(--gray-400);
  --lifecycleUnspecifiedColor: white; //to be defined

  // Prescription status colors
  --prescriptionUndeliveredColor: #167fac;
  --prescriptionDeliveredColor: #02875b;
  --prescriptionInProcessColor: #f2aa46;
  --prescriptionRevokedColor: #721d73;
  --prescriptionExpiredColor: #d12f30;
  --prescriptionOnPaperColor: #ffffff;
  --prescriptionArchivedColor: #e5ebef;

  // Chapter4 application status colors
  --chapter4ApplicationApprovedColor: #02875b;
  --chapter4ApplicationEndedColor: #721d73;
  --chapter4ApplicationErrorColor: #d12f30;
  --chapter4ApplicationEvaluatingColor: #167fac;
  --chapter4ApplicationIncompleteColor: #f2aa46;
  --chapter4ApplicationRefusedColor: #d12f30;
  --chapter4ApplicationRevokedColor: #721d73;
  --chapter4ApplicationUndefinedColor: var(--undefinedColor);

  // Alerts
  --alert-margin-bot: 1rem;

  // sidebar
  --sidebarWidth: 30rem;
}
